import { render, staticRenderFns } from "./UserMetaUnivAndMajorInput.vue?vue&type=template&id=4dc045ce&scoped=true&"
import script from "./UserMetaUnivAndMajorInput.vue?vue&type=script&lang=js&"
export * from "./UserMetaUnivAndMajorInput.vue?vue&type=script&lang=js&"
import style0 from "./UserMetaUnivAndMajorInput.vue?vue&type=style&index=0&id=4dc045ce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc045ce",
  null
  
)

export default component.exports