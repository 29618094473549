
import { defineComponent } from 'vue';

import { highlight } from '@/utils/highlight';

export default defineComponent({
	name: 'SearchAutoCompleteList',
	props: {
		searchKeyword: {
			type: String,
			default: '',
		},
		autoCompleteList: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		hasNoResult() {
			return Boolean(this.searchKeyword) && this.autoCompleteList.length < 1;
		},
	},
	methods: {
		highlight,
		clickAutocomplete(keyword) {
			this.$emit('click-auto-complete', keyword);
		},
	},
});
