
import debounce from 'lodash/debounce';

import { jsonToArray } from '@/utils/utils';

import SearchAutoCompleteList from '@/components/search/common/SearchAutoCompleteList.vue';

import layoutMixin from '@/mixins/layoutMixin';
import loadingMixin from '@/mixins/loadingMixin';

export default {
	name: 'UserMetaInterestDepartment',
	mixins: [loadingMixin, layoutMixin],
	props: {
		interestDepartment: {
			type: Array,
			default: () => [],
		},
		searchInputMaxWidth: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			showAutoCompleteDropdown: false,
			interestDepartmentText: '',
			autoCompleteMockList: [],
			isDepartmentMaxMessage: false,
		};
	},
	computed: {
		showAutoCompleteList() {
			return Boolean(this.interestDepartmentText);
		},
	},
	methods: {
		controlShowAutoCompleteDropdown(value) {
			this.showAutoCompleteDropdown = value;
		},
		updateInterestDepartment(value) {
			this.$emit('update:interestDepartment', value);
		},
		clickAutoComplete(item) {
			if (this.interestDepartment.includes(item)) {
				return;
			}
			if (this.interestDepartment.length >= 5) {
				this.isDepartmentMaxMessage = true;
				return;
			}
			const selectedDepartments = [...this.interestDepartment, item];
			this.updateInterestDepartment(selectedDepartments);
			this.controlShowAutoCompleteDropdown(false);
		},
		removeDepartmentItem(item) {
			const interestDepartment = [...(this.interestDepartment || [])];
			const itemIndex = this.interestDepartment.indexOf(item);
			interestDepartment.splice(itemIndex, 1);
			this.updateInterestDepartment(interestDepartment);
		},
		fetchAutocomplete: debounce(async function () {
			try {
				const params = { keyword: this.interestDepartmentText };
				const { data } = await this.$api.autocomplete.getAutocompleteJob(params);
				this.autoCompleteMockList = jsonToArray(data);
			} catch (error) {
				console.error(error);
			}
		}, 200),
	},
	components: { SearchAutoCompleteList },
};
