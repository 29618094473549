import { getLocalStorage, setLocalStorage } from '@/utils/utils';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const HAS_POTENTIAL_PARTNER_USER_TRIGGERED = `HAS_POTENTIAL_PARTNER_USER_TRIGGERED`;

const gtmPotentialPartnerUserMixin = {
	mixins: [userInfoMixin, gtmTriggerMixin],
	methods: {
		async $_handleTriggerPotentialPartnerUser({ univ, major }) {
			try {
				// 이전에 발동한 적 있는지
				if (getLocalStorage(this.$_getHasPotentialPartnerUserTriggeredKey())) {
					return;
				}
				const {
					data: { result },
				} = await this.$api.case.checkMentoringPartnerApplyAble({
					univ,
				});
				// 멘토링 제휴 대학이 아니거나
				if (!result) {
					return;
				}
				// GTM - potentialPartnerUser
				this.$_triggerPotentialPartnerUser({ univ, major });
				setLocalStorage(this.$_getHasPotentialPartnerUserTriggeredKey(), 'true');
			} catch (error) {
				console.error('handleTriggerPotentialPartnerUser : ', error);
			}
		},
		$_triggerPotentialPartnerUser({ univ, major }) {
			this.$_potentialPartnerUser({
				...this.$_userInfoForGtm,
				user_university: univ,
				user_major: major,
			});
		},
		$_getHasPotentialPartnerUserTriggeredKey() {
			return `${HAS_POTENTIAL_PARTNER_USER_TRIGGERED}_${this.userNo}`;
		},
	},
};
export default gtmPotentialPartnerUserMixin;
