export function highlightSearchContent(textContent, maxLength, keyword) {
	let result;
	const keywordIndex = getKeywordIndex(textContent, keyword);
	const slicedText = textContent.substring(keywordIndex);
	if (keywordIndex > maxLength) {
		const slicedSearchContent = addSubstringAndAddMoreText(slicedText, maxLength);
		result = handleHighlightKeyword(`...${slicedSearchContent}`, keyword);
	} else if (textContent.length > maxLength) {
		// 단어별로 자르고 길면 '...더보기' 붙이기
		result = addSubstringAndAddMoreText(textContent, maxLength);
	} else {
		result = textContent;
	}
	return handleHighlightKeyword(result, keyword);
}
export function handleHighlightKeyword(data, keyword) {
	const hasKeywordSpace = Boolean(keyword.indexOf(' '));
	if (hasKeywordSpace) {
		let result = data;
		const keywordTerms = keyword.split(' ');
		for (const term of keywordTerms) {
			result = highlight(term, result);
		}
		return result;
	}
	return highlight(keyword, data);
}
export function addSubstringAndAddMoreText(content, maxLength) {
	const moreText = `...<span class="more-text">더보기</span>`;
	const sliceContent = String(content).slice(0, maxLength);
	const lastSpaceCharIndex = sliceContent.search(/ [^ ]*$/);
	return `${sliceContent.slice(0, lastSpaceCharIndex > 10 ? lastSpaceCharIndex : 10)}${moreText}`;
}
function getKeywordIndex(content, keyword) {
	const keywordTerms = keyword.split(' ');
	return findEarliestIndex(content, keywordTerms);
}
function findEarliestIndex(text, terms) {
	let earliestIndex = text.length;

	for (const term of terms) {
		const index = text.indexOf(term);
		if (index !== -1 && index < earliestIndex) {
			earliestIndex = index;
		}
	}
	return earliestIndex !== text.length ? earliestIndex : -1;
}

/**
 * 특정 문자열에 해당하는 부분을 하이라이트 하는 태그를 리턴하는 함수
 * @param data: 특정 문자열
 * @param value: 전체 문자열
 * @return String
 */
export function highlight(data, value) {
	if (!data || !value) {
		return value;
	}

	const tr = data
		.split(' ')
		.join('|')
		.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	return value.replace(new RegExp(tr, 'gi'), match => {
		return `<span class="highlight-text">${match}</span>`;
	});
}
