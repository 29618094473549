
import debounce from 'lodash/debounce';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'UserMetaUnivAndMajorInput',
	props: {
		id: {
			type: String,
			default: '',
			required: true,
		},
		placeholder: {
			type: String,
			default: '내용을 입력해주세요.',
		},
		value: {
			type: String,
			default: '',
		},
		isShowAppend: {
			type: Boolean,
			default: false,
		},
		userMetaTextValue: {
			type: String,
			default: '',
		},
		textLimit: {
			type: Number,
			default: 20,
		},
	},
	computed: {
		sync_value: {
			get() {
				const value = this.value;
				if (value && value.length >= this.textLimit) {
					return `${value.slice(0, this.textLimit - 1)}...`;
				}
				return value;
			},
			set(newValue) {
				return this.$emit('update:value', newValue);
			},
		},
		sync_text_value: {
			get() {
				return this.userMetaTextValue;
			},
			set(newValue) {
				return this.$emit('update:user-meta-text-value', newValue);
			},
		},
	},
	methods: {
		clickCloseButton() {
			this.sync_value = '';
		},
		emitFocusEvent() {
			this.$emit('user-meta-focus');
		},
		emitKeydownEvent: debounce(function () {
			this.$emit('user-meta-keydown');
		}, 2000),
	},
});
